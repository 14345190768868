import { Injectable } from '@angular/core';
import { RequestAttribute } from 'src/utils/models/http.interface';
import { HttpService } from 'src/app/service/http/http.service';
import { isOnline, searchInObject, sortAsc, textList } from 'src/utils/functions/utilities.service';
import { IndexedDbService } from 'src/app/shared/database/indexed-db.service';
import mockTypes from '../../../../../utils/constants/mocks/common/components/types.mock.json';

@Injectable({
  providedIn: 'root'
})
export class SelectSpicService {
  statusOnline = isOnline;
  listText = textList;
  constructor(
    private readonly httpService: HttpService,
    private readonly indexedDbService: IndexedDbService
  ) { }

  returnTypeSchema(endpoint: string): string {
    let schema = '';
    if (endpoint === 'search') {
      schema = 'searchDocuments';
    }
    return schema;
  }

  returnTextDescription(type: string, res: any, value: string): string {
    const text = this.listText(res, value)
    if (text) {
      return text;
    } else {
      return this.returnDescriptionSchema(type);
    }
  }

  returnDescriptionSchema(type: string): string {
    let property = '';
    switch(type) {
      case 'pt':
        property = 'serviceDescription';
        break;
      case 'os':
        property = 'observation';
        break;
      case 'si':
        property = 'description';
        break;
      case 'ss':
        property = 'description';
        break;
    }
    return property;
  }

  searchOffline(data: any, value: string, attributes: RequestAttribute[]) {
    const dataValue = (value === undefined ? [] : data.filter(res => searchInObject(res, value, attributes, 'all'))), separateByTypes = {
      pt: [], si: [], ss: [], os: []
    }, returnValue = [];
    dataValue.forEach(item => {
      if (separateByTypes[item.type] !== undefined) {
        separateByTypes[item.type].push({
          id: item._id,
          value: item[this.returnTextDescription(item.type, item, value)]
        })
      }
    });
    Object.keys(separateByTypes).forEach(item => {
      if (separateByTypes[item].length > 0) {
        separateByTypes[item] = separateByTypes[item].filter((response, index, arr) =>
          arr.findIndex(res => res[this.returnDescriptionSchema(response.type)] === response[response.type]) === index
        ).sort((a, b) => sortAsc(a, b, item));
        returnValue.push({
          name: mockTypes[item],
          options: separateByTypes[item]
        })
      }
    });
    return {
      withGroup: returnValue
    };
  }

  listDataSelect(endpoint: string, value: string, searchAttibute: string, extraParams: RequestAttribute[], sugestion?: boolean) {
    if (this.statusOnline()) {
      const params = [ ...extraParams ];
      params.push({ param: searchAttibute, value: (value || '') });
      if (sugestion) {
        params.push({ param: 'sugestion', value: 'true' });
      }
      return this.httpService.genericGetParams(endpoint, params);
    } else {
      return this.indexedDbService.getAllSchema('documents');
    }
  }
}
