import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TextInputSpicInterface } from 'src/utils/models/inputs.interface';

@Component({
  selector: 'input-text-spic',
  templateUrl: './input-text-spic.component.html',
  styleUrls: ['./input-text-spic.component.scss']
})
export class InputTextSpicComponent implements OnInit, OnChanges {
  @Input() textInterface: TextInputSpicInterface;
  @Input() changeSelect: number;
  @Output() valueSelected: EventEmitter<any> = new EventEmitter();

  valueInput: any;

  ngOnInit(): void {
    this.valueInput = this.textInterface?.value;
  }

  ngOnChanges(changes): void {
    if (changes?.changeSelect && !changes.changeSelect.firstChange) {
      this.valueInput = this.textInterface.value;
    }
  }

  handleChangeInput(): void {
    this.valueSelected.emit({
      key: this.textInterface.key,
      param: this.textInterface.paramName,
      value: this.valueInput
    });
  }
}
