import { Component } from '@angular/core';
import icons from '../utils/constants/layout/icons.json';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeService } from '@induxtry/library';
import theme from '../utils/constants/layout/colors.json';
import { HttpClient } from '@angular/common/http';
import { removeItemSessionStorage } from 'src/utils/functions/utilities.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  iconsArray = icons.icons;
  otherIcons = icons.icons_menor;
  feedbackArray = icons.feedback;
  loadingArray = icons.loading;
  title = 'CheckUDoc';

  constructor(
    private readonly iconregistry: MatIconRegistry,
    private readonly sanitizer: DomSanitizer,
    private readonly themeService: ThemeService,
    private readonly http: HttpClient
  ) {
    this.themeService.setTheme(theme);

    this.iconsArray.forEach(icon => {this.regitryIcons(icon, iconregistry, sanitizer)});

    this.otherIcons.forEach(icon => {this.regitryIcons(icon, iconregistry, sanitizer)});

    this.feedbackArray.forEach(icon => {this.regitryIcons(icon, iconregistry, sanitizer)});

    this.loadingArray.forEach(icon => {this.regitryIcons(icon, iconregistry, sanitizer)});

    window.onload = () => this.handleClearSearchSessionStorage();
  }

  regitryIcons(icon: any, iconregistry: any, sanitizer: any): void {
    if (icon.cache) {
      this.loadSvgFile(`assets/icons/${icon.path}`).then((res: any) => {
        iconregistry.addSvgIconLiteral(icon.icon, sanitizer.bypassSecurityTrustHtml(`${res}`));
      });
    } else {
      iconregistry.addSvgIcon(icon.icon,
        sanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${icon.path}`));
    }
  }

  loadSvgFile(filePath: string): Promise<string> {
    return new Promise((resolve) => {
      this.http.get(filePath, { responseType: 'text' }).subscribe(
        (data) => {
          resolve(data);
        }
      );
    })
  }

  handleClearSearchSessionStorage() {
    removeItemSessionStorage('search');
  }
}
