import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/service/auth/auth.service';
import { isOnline, isOnlineEmitter } from 'src/utils/functions/utilities.service';
import { IndexedDbService } from '../../database/indexed-db.service';

@Component({
  selector: 'avatar-spic',
  templateUrl: './avatar-spic.component.html',
  styleUrls: ['./avatar-spic.component.scss']
})
export class AvatarSpicComponent implements OnInit {
  openProfile: boolean;
  name: string;
  firstCharacters: string;
  online = isOnline();
  emitterOnline = isOnlineEmitter();

  constructor(
    public router: Router,
    private readonly auth: AuthService,
    private readonly toastr: ToastrService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly indexedDbService: IndexedDbService
  ) { }

  ngOnInit(): void {
    this.name = (localStorage.getItem('name') ? localStorage.getItem('name') : '');
    this.firstCharacters = this.extractFirstCharacters();

    this.emitterOnline.subscribe(state => {
      this.online = state;
      this.changeDetectorRef.detectChanges();
    });
  }

  extractFirstCharacters(): string {
    const arrayName = this.name.split(' ');
    let nowCharacter = '';
    arrayName.forEach((item, index, arr) => {
      if (index <= 1) {
        nowCharacter += item.substring(0, 1);
      } else {
        arr.length = index + 1;
      }
    })
    return nowCharacter.toUpperCase();
  }

  handleClickOpenProfile(): void {
    this.openProfile = true;
  }

  handleClickCloseProfile(): void {
    this.openProfile = false;
  }

  closeCloseProfile(event): void {
    if (event.currentTarget === event.target) {
      this.handleClickCloseProfile();
    }
  }

  handleExit() {
    this.router.navigate(['/autenticacao/login']);
    this.auth.logoutPwa();
    this.indexedDbService.deleteAllBySchema('documents').subscribe();
    this.indexedDbService.deleteAllBySchema('updates').subscribe();
    this.toastr.show('Logout efetuado com sucesso!', 'Sucesso!', {
      timeOut: 5000,
      messageClass: 'success',
      titleClass: JSON.stringify({})
    });
  }
}
