import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { SelectSpicService } from './services/select-spic.service';
import { SelectSpicInterface } from 'src/utils/models/select.interface';
import { ToastrService } from 'ngx-toastr';
import { isOnline, isOnlineEmitter, scrollToTop } from 'src/utils/functions/utilities.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'select-spic',
  templateUrl: './select-spic.component.html',
  styleUrls: ['./select-spic.component.scss']
})
export class SelectSpicComponent implements OnInit, OnChanges, OnDestroy {
  subscribeDestroyed: Subject<any> = new Subject();
  @Input() selectInterface: SelectSpicInterface;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();
  @Output() selectionKeyUp: EventEmitter<any> = new EventEmitter();

  constructor(
    private readonly selectSpicService: SelectSpicService,
    private readonly toastr: ToastrService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) { }

  valueSearch = null;
  beforeValueSearch = null;
  openSuggestions: boolean;
  options: any = [];
  withGroup: boolean;
  online = isOnline();
  intervalId = null;
  emitterOnline = isOnlineEmitter();

  sessionSearch = sessionStorage.getItem('search');

  ngOnInit(): void {
    this.valueSearch = this.selectInterface.value;
    if (this.sessionSearch) {
      this.handleSessionSearch();
    }
    this.intervalId = setInterval(() => {
      if (this.beforeValueSearch !== this.valueSearch && this.selectInterface?.sugestion !== false) {
        this.getListDataSelect(this.valueSearch);
        this.beforeValueSearch = this.valueSearch;
      }
    }, 1500);
    this.emitterOnline.subscribe(state => {
      this.online = state;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnChanges(changes): void {
    if (
      changes?.selectInterface &&
      !changes.selectInterface.firstChange &&
      changes.selectInterface.previousValue.value !== changes.selectInterface.currentValue.value
    ) {
      this.valueSearch = changes.selectInterface.currentValue.value
    }
  }

  handleSessionSearch() {
    const valueSession: any = JSON.parse(this.sessionSearch);
    this.handleClickSearch(valueSession.searchValue);
  }

  verifyWithGroup(res: any) {
    if (res.withGroup !== null && res.withGroup !== undefined) {
      this.withGroup = true;
      return res.withGroup;
    }
    this.withGroup = false;
    return res.notGroup;
  }

  getListDataSelect(value) {
    return new Promise((resolve) => {
      this.selectSpicService.listDataSelect(
        this.selectInterface.endpoint,
        value,
        this.selectInterface.inputSearch,
        this.selectInterface.attributes,
        this.selectInterface.sugestion || false
      ).pipe(
        takeUntil(this.subscribeDestroyed)
      ).subscribe((res: any) => {
        this.options = (this.online ? this.verifyWithGroup(res) : this.verifyWithGroup(this.selectSpicService.searchOffline(res, value, this.selectInterface.attributes)));
        resolve(true);
      }, (error) => {
        this.options = [];
        if (this.toastr.toasts.findIndex(res => res.title === 'Erro ao buscar!') === -1) {
          this.toastr.show('Erro ao buscar documentos.', 'Erro ao buscar!', {
            timeOut: 5000,
            messageClass: 'error',
            titleClass: JSON.stringify({})
          });
        }
        resolve(true);
      });
    })
  }

  async handleKeyupValue(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.selectInterface.value = this.valueSearch;
      this.openSuggestions = false;
      this.selectionChange.emit(this.valueSearch);
      this.selectionKeyUp.emit(this.valueSearch);
      scrollToTop();
    } else {
      this.selectInterface.value = '';
      if (this.valueSearch !== '') {
        this.openSuggestions = (this.options[0] !== null);
        this.selectionKeyUp.emit(this.valueSearch);
      } else {
        this.options = [];
        this.openSuggestions = false;
        this.selectionKeyUp.emit(this.valueSearch);
      }
    }
  }

  clearSearchSelectSpic() {
    this.valueSearch = undefined;
    this.selectInterface.value = this.valueSearch;
    this.openSuggestions = false;
    this.handleClickSearch(this.valueSearch);
  }

  handleClickSearch(value) {
    scrollToTop();
    this.openSuggestions = false;
    this.valueSearch = value;
    this.selectInterface.value = value;
    this.selectionChange.emit(value);
    this.selectionKeyUp.emit(this.valueSearch);
  }

  handleClickOutsideElement() {
    this.openSuggestions = false;
  }

  async handleFocusElement() {
    if (this.valueSearch !== '' && this.selectInterface?.sugestion !== false) {
      this.openSuggestions = (this.options[0] !== null);
    } else {
      this.options = [];
      this.openSuggestions = false;
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalId);
  }
}
