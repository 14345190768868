import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TagSpicInterface } from 'src/utils/models/tags.interface';
import { ActionButtonCardDocument, ButtonsCardDocument, CheckCardDocument, CreateDataCardDocument } from 'src/utils/models/card-document.interface';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { isOnline, isOnlineEmitter, showTheData, verifyShowMobile } from 'src/utils/functions/utilities.service';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'card-document',
  templateUrl: './card-document.component.html',
  styleUrls: ['./card-document.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardDocumentComponent implements OnInit {
  @Input() doc: any;
  @Input() tags: TagSpicInterface[];
  @Input() data: CreateDataCardDocument;
  @Input() buttons: ButtonsCardDocument[] | [];
  @Input() viewMore: boolean;
  @Input() check: CheckCardDocument;

  @Output() actionButton: EventEmitter<ActionButtonCardDocument> = new EventEmitter();
  @Output() toggleCheck: EventEmitter<CheckCardDocument> = new EventEmitter();

  openCard: boolean;
  titleTypeDocument?: string;
  online = isOnline();
  emitterOnline = isOnlineEmitter();
  verifyShow = verifyShowMobile;
  showData = showTheData;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.emitterOnline.subscribe(state => {
      this.online = state;
      this.changeDetectorRef.detectChanges();
    });
  }

  toggleOherInformation() {
    this.openCard = !this.openCard;
  }

  handleClickButton(key: string): void {
    this.actionButton.emit({
      key,
      document: this.doc
    });
  }

  handleClickButtonItem(key: string, item: string) {
    this.actionButton.emit({
      key,
      item,
      document: this.doc
    });
  }

  handleToggleCheck(): void {
    this.check.value = !this.check.value;
    this.toggleCheck.emit(this.check);
  }
}
