import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TagSpicInterface } from 'src/utils/models/tags.interface';

@Component({
  selector: 'tags-spic',
  templateUrl: './tags-spic.component.html',
  styleUrls: ['./tags-spic.component.scss']
})
export class TagsSpicComponent {
  @Input() tagInterface: TagSpicInterface;
  @Output() handleClick: EventEmitter<string> = new EventEmitter();

  handleClickAction(): void {
    this.handleClick.emit(this.tagInterface.key);
  }
}
