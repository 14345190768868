import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataVideoTutorialInterface, TutorialInterface } from 'src/utils/models/titorial.interface';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { DomSanitizer } from '@angular/platform-browser';
import { exportUrlVideo } from 'src/utils/functions/utilities.service';
import { SwiperComponent } from 'swiper/angular';

// install Swiper modules
SwiperCore.use([Navigation, Pagination]);

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'modal-tutorial-spic',
  templateUrl: './modal-tutorial-spic.component.html',
  styleUrls: ['./modal-tutorial-spic.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ModalTutorialSpicComponent implements OnInit, OnDestroy, AfterViewInit {
  desktopDevice = !!(window.innerWidth >= 600);
  initialValue = '';
  showSlide = true;
  countSlides = 1;
  maxCountSlides = 0;
  showMenuMobile = false;
  urlVideos: DataVideoTutorialInterface = {
    desktop: [],
    mobile: []
  };

  resizeEventListener: () => void;

  @ViewChild(SwiperComponent) swiperComponent: SwiperComponent;

  constructor(
    public dialogRef: MatDialogRef<ModalTutorialSpicComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TutorialInterface,
    private readonly router: Router,
    private readonly cdrf: ChangeDetectorRef,
    private readonly _sanitizer: DomSanitizer
  ) {
  }

  ngOnInit(): void {
    this.initialValue = this.findPageSeeTutorial(window.innerWidth >= 600 ? 'desktop' : 'mobile');

    Object.keys(this.urlVideos).forEach(key => {
      this.data.data[key].forEach(res => {
        this.urlVideos[key].push({
          key: res.key,
          urls: res.slide.map(slide => exportUrlVideo(this._sanitizer, slide.video))
        })
      })
    })

    this.resizeEventListener = async () => {
      if (window.innerWidth < 600 && this.desktopDevice) {
        this.handleClickMenu(this.findPageSeeTutorial('mobile'));
        this.desktopDevice = false;
        this.showMenuMobile = false;
      } else if (window.innerWidth >= 600 && !this.desktopDevice){
        this.handleClickMenu(this.findPageSeeTutorial('desktop'));
        this.desktopDevice = true;
        this.showMenuMobile = false;
      }
    };
    window.addEventListener("resize", this.resizeEventListener);
  }

  ngAfterViewInit() {
    this.swiperComponent.swiperRef.update();
  }

  getVideo(key: string, index: number) {
    const video = this.urlVideos[this.desktopDevice ? 'desktop' : 'mobile'].find(res => res.key === key);
    return video && video.urls[index] ? video.urls[index] : '';
  }

  findPageSeeTutorial(device: string): string {
    const endpoint = this.router.url, findKey = this.data.menu[device].find(res => endpoint.includes(res.key));
    return findKey ? findKey.key : this.data.menu[device][0].key;
  }

  prevSlideTutorial(): void {
    if (this.countSlides > 1) {
      this.countSlides--;
    }
  }

  nextSlideTutorial(): void {
    this.countSlides++;
  }

  getData(value: string): any {
    let valueArray = [];
    const findData: any = this.data.data[this.desktopDevice ? 'desktop' : 'mobile'].find(res => res.key === value);
    if (findData) {
      valueArray = findData.slide;
    }
    this.maxCountSlides = valueArray.length;
    return valueArray;
  }

  handleClickMenu(key: string) {
    this.showSlide = false;
    this.initialValue = key;
    this.countSlides = 1;

    setTimeout(() => {
      this.showSlide = true;
      this.cdrf.detectChanges();
    }, 300);
  }

  handleChangeDevice(desktop: boolean): void {
    this.showSlide = false;
    this.desktopDevice = desktop;
    this.initialValue = this.data.menu[desktop ? 'desktop' : 'mobile'][0].key;
    this.countSlides = 1;

    setTimeout(() => {
      this.showSlide = true;
      this.cdrf.detectChanges();
    }, 300);
  }

  handleClickMenuMobile(open: boolean) {
    this.showMenuMobile = open;
  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", this.resizeEventListener);
  }
}
