import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { SelectSearchInterface } from 'src/utils/models/select.interface';
import { RequestAttribute } from 'src/utils/models/http.interface';
import { isOnline, isOnlineEmitter, changeRequestParameter } from 'src/utils/functions/utilities.service';
import { FilterSpicService } from '../filter-spic/services/filter-spic.service';
import { ChipsSpicActivatedInterface, ChipsSpicInterface } from 'src/utils/models/chips.interface';

@Component({
  selector: 'search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnChanges {
  @Input() selectInterface: SelectSearchInterface;
  @Input() filterAttribute: RequestAttribute[];
  @Input() changeFilter: number;
  @Input() changeOrigin: number;

  @Output() valueSelected: EventEmitter<any> = new EventEmitter();
  @Output() returnOrigin: EventEmitter<any> = new EventEmitter();

  valueSearch = '';
  nowOrigin = 'sigom';

  attributesSearch: RequestAttribute[] = [
    {
      param: 'origin',
      value: 'sigom'
    }
  ];
  online = isOnline();
  emitterOnline = isOnlineEmitter();

  origins: ChipsSpicInterface[] = [
    { key: 'sigom', icon: 'ChipsTick', label: 'SIGOM', clickable: true, active: true, close: false },
    { key: 'se_suite', icon: 'ChipsTick', label: 'SE Suite', clickable: true, close: false }
  ];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly filterSpicService: FilterSpicService
  ) { }

  ngOnInit(): void {
    this.selectInterface.attributes = [...this.selectInterface.attributes, ...this.attributesSearch, ...this.filterAttribute];

    this.emitterOnline.subscribe(state => {
      this.online = state;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnChanges(changes): void {
    if (changes?.changeFilter && !changes.changeFilter.firstChange) {
      this.selectInterface.attributes = [...this.attributesSearch, ...this.filterAttribute];
      this.valueSearch = this.selectInterface.value;
    }
    if (changes?.changeOrigin && !changes.changeOrigin.firstChange) {
      this.valueSearch = this.selectInterface.value;
      this.handleChangeOrigins({
        key: 'se_suite',
        active: true
      }, true)
    }

  }

  handleChangeOrigins(value: ChipsSpicActivatedInterface, origin?: boolean) {
    this.resetAdvancedSearch();
    this.origins.forEach(res => { res.active = res.key === value.key; });
    const param = this.attributesSearch.find(res => res.param === 'origin');
    if (param) {
      param.value = value.key;
      this.nowOrigin = value.key;

      if (!origin) {
        this.returnOrigin.emit({
          value: this.valueSearch,
          origin: value.key,
          attributes: this.selectInterface.attributes
        });
      }
    }
  }

  handleClickSearchValue(value) {
    this.valueSearch = value;
    this.valueSelected.emit({
      value: value,
      origin: this.nowOrigin,
      attributes: this.selectInterface.attributes
    });
  }

  clearSearch() {
    this.valueSearch = undefined;
    this.selectInterface.attributes = [
      {
        param: 'origin',
        value: 'sigom'
      }
    ];
  }

  resetAdvancedSearch() {
    this.attributesSearch = [];
    this.attributesSearch = changeRequestParameter('origin', this.nowOrigin, false, this.attributesSearch);
    this.selectInterface.attributes = [...this.attributesSearch];
  }

  handleChangeAdvancedSearch(event: RequestAttribute[]): void {
    this.attributesSearch = event;
    this.selectInterface.attributes = [...this.attributesSearch];
  }

  handleChangeFilter(): void {
    this.filterSpicService.handleChangeOpenFilter();
  }
}
