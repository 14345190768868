import {
  AfterViewInit,
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  HostListener,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  ElementRef,
} from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe, DecimalPipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';

import { DynamicTableDataSource } from './dynamic-table-datasource';

import { HttpService } from './service/httpService.service';

import moment, { now } from 'moment';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { RowsMerge, Span } from './mergeRowsFunction';
import { NavigationService, PageSelectComponent } from '@induxtry/library';
import { RequestAttribute } from 'src/utils/models/http.interface';
import { ScrollService } from './service/scroll.service';
import { ConfigApiDynamicTable, DynamicTable } from 'src/utils/models/table.interface';

const NUMBER_ITENS_DEFAULT = 10;
@Component({
  selector: 'dynamic-table-spic',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', border: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DynamicTableComponent
  implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @ViewChild('tableContainer') tableContainer: ElementRef;


  private readonly _unsubscribe$: Subject<any> = new Subject();

  @ViewChildren('filterTable') filterTables;
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @ViewChild('pageSelect', { static: true }) pageSelect: PageSelectComponent;
  @ViewChildren('checkBox') checkBox: QueryList<any>;

  @Input() dynamicTable: DynamicTable;
  @Input() attributes: RequestAttribute[];
  @Input() manyItens = NUMBER_ITENS_DEFAULT;
  @Input() hasPaginate = true;
  @Input() externalData;
  @Input() showCheckbox = false;
  @Input() hasSort = true;
  @Input() hasDelete = false;
  @Input() totalOnHead = false;
  @Input() attributesSubtable: RequestAttribute[];
  @Input() salveExpand = false
  @Input() compareWith = false
  @Input() pageSizeOptions: number[];


  // Inputs necessarios para fazer o merge nas linhas
  @Input() mergedCells = false;
  @Input() amoutMergedCells: number

  // Output Action
  @Output() edit: EventEmitter<any> = new EventEmitter();
  @Output() duplicate: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<any> = new EventEmitter();
  @Output() view: EventEmitter<any> = new EventEmitter();
  @Output() idsOrders: EventEmitter<any> = new EventEmitter();
  @Output() getEdit: EventEmitter<any> = new EventEmitter();
  @Output() getAdd: EventEmitter<any> = new EventEmitter();
  @Output() modalEdit: EventEmitter<any> = new EventEmitter();
  @Output() subTableFilter: EventEmitter<any> = new EventEmitter();


  // Output Uteis
  @Output() loadedTable: EventEmitter<any> = new EventEmitter();
  @Output() filter: EventEmitter<any> = new EventEmitter();

  dataSource: DynamicTableDataSource;

  _dynamicTable: DynamicTable;
  attributeParams: RequestAttribute[] = [];
  filterParams: RequestAttribute[] = [];

  data: any[] = [];
  editRow: boolean[] = [];
  checked = [];
  checks = new Array(NUMBER_ITENS_DEFAULT).fill(false);
  ords = [];
  ids2 = [];
  ids = [];
  haveSubIds;
  subTotalAttr: any = []
  subTotal: any = [];

  filterOptions = {};

  dateFormat = 'DD/MM/YYYY';
  dateHourFormat = 'DD/MM/YYYY - HH:mm';
  flagFilter = true;
  width;
  dateForm: FormGroup;
  flagEditAll: boolean;
  expandedElement: any = null;
  expandedElements: any[] = [];
  configApi: ConfigApiDynamicTable;

  subTableOpen = false
  headerRowIds: string[]
  unSub: Subscription
  rowSpanComputer = new RowsMerge();
  rowSpans: Array<Span[]>;
  dataRows: any = [];
  indexSubTable = 0;
  expandedItem = false;
  expandAll = false;
  notFirst: boolean;
  optionsSelect = [];

  constructor(
    private readonly decimalPipe: DecimalPipe,
    private readonly datePipe: DatePipe,
    private readonly formBuilder: FormBuilder,
    public dialog: MatDialog,
    public httpService: HttpService,
    private readonly nav: NavigationService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly scrollService: ScrollService
  ) {
    this.dateForm = this.formBuilder.group({
      date: [null],
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.width = event.target.innerWidth;
    this.reloadSticky();
  }

  ngOnInit(): void {
    this.configApi = this.dynamicTable.configApi;
    if (this.mergedCells) {
      //Passa para o dataSource, merge true e a quantidade de linhas que será "mergeada"
      this.dataSource = new DynamicTableDataSource(this.httpService,  this.configApi, this.dialog, this.externalData, this.amoutMergedCells);
    } else {
      this.dataSource = new DynamicTableDataSource(this.httpService,  this.configApi, this.dialog, this.externalData);
    }
    if (this.attributes !== undefined) {
      this.attributeParams = this.attributes;
    }
    this._dynamicTable.requestParams.limit = this.manyItens;
    this.dataSource.loadRequest(
      this._dynamicTable.endpoint,
      this._dynamicTable.requestParams,
      this.attributeParams,
      this.filterParams
    );

    const hasSomeFilter = this._dynamicTable.displayedColumns.some(column => column.hasFilter === true);
    if (hasSomeFilter) {
      this.dataSource.loadFilters(
        this._dynamicTable.endpoint,
        this._dynamicTable.requestParams,
        this.attributeParams
      );
    }
    if (this.mergedCells) {
      this.rowsMerge();
    }
    if (this._dynamicTable.multipleTotals) {
      this.dataSource.data$.subscribe((data: any) => {
        this.convertDataMultipleTotais(data);
      });
    }
  }

  openedSelectTable(selectInterfaceColumn): void {
    this.optionsSelect = [];
    this.httpService.genericGetSelect(selectInterfaceColumn).subscribe(response => {
      this.optionsSelect = response;
    })
  }

  addValuesTotalLine(data: any[], item: any): any {
    data = Array.from(new Set(data));
    const attributesContainsTotal = this._dynamicTable?.groupMultipleTotals.attributesContainsTotal;
    if (attributesContainsTotal) {
      attributesContainsTotal.forEach(attribute => {
        let totalValue = 0;
        data.forEach(value => {
          if (value[attribute]) {
            totalValue += parseInt(value[attribute]);
          }
        });
        item[attribute] = totalValue;
      });
      this._dynamicTable.columns.forEach(column => {
        if (attributesContainsTotal.indexOf(column) === -1) {
          if (column === this._dynamicTable?.groupMultipleTotals.attributeLabel) {
            item[column] = this._dynamicTable?.groupMultipleTotals.label;
          } else {
            item[column] = '';
          }
        }
      });
    }
    return item;
  }

  convertDataMultipleTotais(data: any): void {
    const propertyGroup = this._dynamicTable?.groupMultipleTotals.groupLabel;
    let nowGroup = (data.length > 0 && data[0][propertyGroup] ? data[0][propertyGroup] : null);
    let indexInsert = [];
    if (nowGroup) {
      let nowItem: any;
      let beforeGroup = [];
      data.forEach((item, index) => {
        if (nowGroup !== item[propertyGroup]) {
          nowItem = { totalLine: true, group: nowGroup };
          nowItem = this.addValuesTotalLine(beforeGroup, nowItem);
          indexInsert.push({
            index: index,
            property: nowGroup,
            nowItem: nowItem
          })
          nowGroup = item[propertyGroup];
          beforeGroup = [];
        }
        beforeGroup.push(item);
      });
      nowItem = { totalLine: true, group: nowGroup };
      nowItem = this.addValuesTotalLine(beforeGroup, nowItem)
      indexInsert.push({
        index: data.length,
        property: nowGroup,
        nowItem: nowItem
      })
      indexInsert.forEach((item, index) => {
        const indexTotalElement = data.findIndex(res => res.totalLine === true && res.group === item.nowItem.group);
        if (indexTotalElement !== -1) {
          data[indexTotalElement] = item.nowItem;
        } else {
          Array.prototype.splice.apply(data, [(item.index + index), 0].concat([item.nowItem]))
        }
      });
      this.removeMultipleTotalLines(data);
      data.filter(res => !((res.group === undefined || res.group === '') && res.totalLine === true));
      this.cdRef.markForCheck();
    }
  }

  removeMultipleTotalLines(data) {
    const arrayLines = data.filter(res => (!res.group && res.totalLine === true));
    arrayLines.forEach(element => {
      const indexLine = data.findIndex(res => res === element);
      if (indexLine !== -1) {
        data.splice(indexLine, 1)
      }
    });
  }

  ngAfterViewInit() {
    this.tableContainer.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    this.unSub = this.scrollService.newEvent.subscribe((res) => {
      this.tableContainer.nativeElement.scrollLeft = res;
    })

    this.width = window.innerWidth;

    this.dataSource.filters$.subscribe((data) => {
      this.setFilterOptions(data[0]);
    });

    this.dataSource.data$.subscribe((data: any) => {
      data.forEach((item, index) => {
        if (this.checked.includes(item.id)) {
          this.checks[index] = true;
        } else {
          this.checks[index] = false;
        }
      });

      if (data.length) {
        let aux = '';
        data.forEach((e) => {
          if (e.favoriteMachines) {
            e.favoriteMachines.forEach((f) => {
              aux += f + ',';
            });
            e.favoriteMachines = aux.replace(/,\s*$/, ' ');
            aux = '';
          }
        });

        if (data[0]?.dates) {
          this._dynamicTable = JSON.parse(JSON.stringify(this.dynamicTable));
          data.forEach((e, index) => {
            data[index].dates.forEach((element, i) => {
              e[`dates${i}`] = element.value;
            });
          });

          const dynamicAux = this._dynamicTable.columns.pop();
          data[0].dates.forEach((e, i) => {
            this._dynamicTable.columns.push(`dates${i}`);
            this._dynamicTable.displayedColumns.push({
              attribute: `dates${i}`,
              title: e.date,
              type: 'number',
              sticky: false,
              subtitle: {
                name: '',
                lock: true,
              },
            });
          });
          this._dynamicTable.columns.push(dynamicAux);
        }
      }

      this.data = data;

      this.loadedTable.emit(data);
      this.reloadSticky();
      this.editRow = new Array(this.data.length).fill(false);
    });

    if (this.dynamicTable.headersColuns) {
      this.headerRowIds = this._dynamicTable.headersColuns.map(row => row.id)
    }
    if (this.totalOnHead) {
      this.getTotalValues(this.data);
    }
    this.cdRef.detectChanges();
  }

  ngOnChanges(): void {
    if (this.dynamicTable) {
      this._dynamicTable = JSON.parse(JSON.stringify(this.dynamicTable));
    }
    if (!this.showCheckbox) {
      this.uncheckAll();
    }
    if (this.hasDelete) {
      this.ids = this.data.map(data => data.id)
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next('');
    this._unsubscribe$.complete();
    this.unSub.unsubscribe();
  }

  changePage(event): void {
    this._dynamicTable.requestParams.page = event;
    this.loadTable();
  }

  changeManyItens(event): void {
    this._dynamicTable.requestParams.limit = event;
    this._dynamicTable.requestParams.page = 1;
    this.loadTable();
  }

  trackArray(index): number {
    return index;
  }

  async loadTable(reloadFilter = false) {
    if (reloadFilter) {
      this.dataSource.loadFilters(this._dynamicTable.endpoint, this._dynamicTable.requestParams, this.attributeParams);
    }
    this.attributesSubtable = [];
    this.dataSource.loadRequest(this._dynamicTable.endpoint, this._dynamicTable.requestParams, this.attributeParams, this.filterParams);
  }
  async reloadSticky() {
    await this.sleep(1);
    this.table.updateStickyColumnStyles();
  }

  sortFunctionData(a, b) {
    let lenghtSort = 0;
    const typeData = typeof a[this._dynamicTable.requestParams.sort];
    if (typeData === 'undefined') {
      lenghtSort = (a[this._dynamicTable.requestParams.sort] === undefined && b[this._dynamicTable.requestParams.sort] !== undefined) ? 1 : -1;
    } else if (typeData === 'object') {
      const strA = a[this._dynamicTable.requestParams.sort].toString().toLowerCase();
      const strB = b[this._dynamicTable.requestParams.sort].toString().toLowerCase();
      lenghtSort = this.validObjectTypeSort(strA, strB);
    } else if (this.validAllDate(a[this._dynamicTable.requestParams.sort], b[this._dynamicTable.requestParams.sort])) {
      const dates = this.attributeDateSort(a[this._dynamicTable.requestParams.sort], b[this._dynamicTable.requestParams.sort]);
      lenghtSort = this.validLenghtSort(dates.firstDate, dates.secondDate);
    } else if (!isNaN(parseFloat(a[this._dynamicTable.requestParams.sort]))) {
      const auxA = parseFloat(a[this._dynamicTable.requestParams.sort]);
      const auxB = parseFloat(b[this._dynamicTable.requestParams.sort]);
      lenghtSort = this.validLenghtSort(auxA, auxB);
    } else if (typeData === 'number') {
      lenghtSort = this.validLenghtSort(a[this._dynamicTable.requestParams.sort], b[this._dynamicTable.requestParams.sort]);
    } else if (typeData === 'string') {
      const strA = a[this._dynamicTable.requestParams.sort].toString().toLowerCase();
      const strB = b[this._dynamicTable.requestParams.sort].toString().toLowerCase();

      lenghtSort = this.validLenghtSort(strA, strB);
    }

    return lenghtSort;
  }

  validLenghtSort(a, b) {
    return (a > b) ? 1 : -1;
  }

  validObjectTypeSort(objectA, objectB) {
    if (objectA === '' && objectB !== '') {
      return 1;
    } else if (objectA !== '' && objectB === '') {
      return -1;
    }

    return this.validLenghtSort(objectA, objectB);
  }

  validDateSort(date, format) {
    return moment(date, format, true).isValid();
  }

  validAllDate(dateA, dateB) {
    return (this.validDateSort(dateA, this.dateFormat) && this.validDateSort(dateB, this.dateFormat)) ||
      (this.validDateSort(dateA, this.dateHourFormat) && this.validDateSort(dateB, this.dateHourFormat));
  }

  attributeDateSort(firstDate, secondDate) {
    let dateA;
    let dateB;
    if (this.validDateSort(firstDate, this.dateFormat)) {
      dateA = moment(firstDate, this.dateFormat, true);
      dateB = moment(secondDate, this.dateFormat, true);
    } else {
      dateA = moment(firstDate, this.dateHourFormat, true);
      dateB = moment(secondDate, this.dateHourFormat, true);
    }

    return { 'firstDate': dateA, 'secondDate': dateB };
  }


  handleSort(att: string): void {
    if (this._dynamicTable.requestParams.sort === att) {
      if (this._dynamicTable.requestParams.order === 'desc') {
        this._dynamicTable.requestParams.order = 'asc';
        this._dynamicTable.requestParams.sort = att;
      } else if (this._dynamicTable.requestParams.order === 'asc') {
        this._dynamicTable.requestParams.order = 'desc';
        this._dynamicTable.requestParams.sort = att;
      }
    } else {
      this._dynamicTable.requestParams.order = 'asc';
      this._dynamicTable.requestParams.sort = att;
    }
    this.data.sort((a, b) => this.sortFunctionData(a, b));

    if (this._dynamicTable.requestParams.order === 'desc') {
      this.data.reverse();
    }

    this.dataSource.loadRequestOrder(this.data)
  }

  emptyText(e: any, limit?: number) {
    if (limit) {
      return e ? (`${e}`.length > limit ? `${`${e}`.substring(0,(limit - 3))}...` : `${e}`) : '-';
    } else {
      return e ? e : '-';
    }
  }

  setFilterOptions(options: any) {
    this._dynamicTable.displayedColumns.forEach((column) => {
      this.filterOptions[column.attribute] = [];
    });
    if (options) {
      Object.keys(options).forEach((key) => {
        if (options[key]) {
          const aux = this._dynamicTable.displayedColumns.find(
            (item) => item.attribute === key
          );
          if (aux) {
            const tipo = aux.type;
            options[key].forEach((element) => {
              this.updateFilterElements(key, element, tipo);
            });
          }
        }
      });
    }
  }

  updateFilterElements(key, element, tipo) {
    if (element === null) {
      this.filterElement(key, element, ' ');
    } else if (tipo === 'number') {
      this.filterElement(key, element, this.decimalPipe.transform(element, '1.0-2'));
    } else if (tipo === 'text' || tipo === 'array') {
      this.filterElement(key, element, element);
    } else if (tipo === 'date') {
      this.filterElement(key, element, this.datePipe.transform(element, 'dd/MM/yyyy'));
    } else if (tipo === 'date-time') {
      this.filterElement(key, element, this.datePipe.transform(element, "dd/MM/yyyy 'às' HH:mm"));
    } else if (tipo === 'bool') {
      this.filterElement(key, element, this.elementBoolValid(element));
    }
  }

  elementBoolValid(element) {
    return element ? 'Sim' : 'Não';
  }

  filterElement(key, valueElement, friendlyElement) {
    this.filterOptions[key].push({
      value: valueElement,
      friendly: friendlyElement,
    });
  }

  handleFilter(filters: any, att: string) {
    const index = this.filterParams.findIndex(item => item.param === att);

    if (index > -1) {
      this.filterParams.splice(index, 1);
    }

    if (filters.filter.length > 0) {
      if (['activities', 'routes', 'carbonContent'].includes(att)) {
        this.filterParams.push({
          param: att,
          value: filters.filter.map(item => item.value).join(',')

        });
      } else {
        let valueAtt = '';
        if (typeof filters.filter[0] === 'object') {
          if (att === 'user') {
            valueAtt = filters.filter.map(item => item.value._id.replace(/,/g, '.')).join(',');
          } else {
            valueAtt = filters.filter.map(item => item.value.replace(/,/g, '.')).join(',');
          }
        } else {
          valueAtt = filters.filter.join(',');
        }
        this.filterParams.push({
          param: att,
          value: valueAtt
        });
      }

    }

    this.flagEditAll = this.filterParams.length > 0 ? true : false
    this.filter.emit(this.filterParams);
    this._dynamicTable.requestParams.page = 1;
    this.loadTable();
  }

  handlePin(column: any) {
    if (!column.sticky) {
      column['sticky'] = true;
    } else {
      column.sticky = !column.sticky;
    }
  }

  handleEdit(event: any, subTable?): void {
    let emitSubtable = event;
    if (subTable) {
      emitSubtable = {
        subTableEdit: true,
        obj: event
      }
    }
    this.editRow = new Array(this.data.length).fill(false);
    this.getEdit.emit(this.editRow);
    this.edit.emit(emitSubtable);
  }

  handleDuplicate(event: any, subTable?): void {
    if (subTable) {
      const duplicateSub = {
        subTableDupli: true,
        obj: event
      }
      this.duplicate.emit(duplicateSub);
    } else {
      this.duplicate.emit(event);
    }
  }

  handleDelete(event: any, subTable?, dataIndex?): void {
    if (subTable) {
      const deleteLineSub = {
        subTableDelete: true,
        obj: event
      }
      this.delete.emit(deleteLineSub);
    } else {
      this.deleteAdd(event, dataIndex)
      this.delete.emit(event);
    }
  }

  handleView(event: any, subTable?): void {
    if (subTable) {
      const viewLineSub = {
        subTableView: true,
        obj: event
      }
      this.view.emit(viewLineSub);
    } else {
      this.view.emit(event);
    }
  }

  modalEditElement(event: any, subTable?): void {
    if (subTable) {
      const modalEditLineSub = {
        subTableView: true,
        obj: event
      }
      this.modalEdit.emit(modalEditLineSub);
    } else {
      this.modalEdit.emit(event);
    }
  }

  verifyErrorsMultipleTotalColumns(element, attribute): boolean {
    element = parseInt(element);
    const attributesContainsTotal = this._dynamicTable?.groupMultipleTotals.attributesContainsTotal;
    if (
      element >= 0 &&
      attribute &&
      attributesContainsTotal.indexOf(attribute) !== -1 &&
      (element < this._dynamicTable?.groupMultipleTotals.min ||
      element > this._dynamicTable?.groupMultipleTotals.max)
    ) {
      return true;
    }
    return false;
  }

  changeMultipleTotal(data, indexRow) {
    const propertyGroup = this._dynamicTable?.groupMultipleTotals.groupLabel;
    const element = data[indexRow];
    let nowItem: any;
    if (element) {
      const values = data.filter(res => res[propertyGroup] === element[propertyGroup]);
      nowItem = { totalLine: true, group: element[propertyGroup] };
      nowItem = this.addValuesTotalLine(values, nowItem);
      const totalElement = data.find(res => res.group === element[propertyGroup] && res.totalLine === true);
      const attributesContainsTotal = this._dynamicTable?.groupMultipleTotals.attributesContainsTotal;
      if (totalElement && attributesContainsTotal) {
        attributesContainsTotal.forEach(attribute => {
          totalElement[attribute] = nowItem[attribute];
        });
      }
    }
  }

  setEdit(indexRow, subTable?) {
    if (subTable) {
      this.editRow[indexRow?.indexTableLine] = true;
      const editedItens = {
        editRow: this.editRow,
        editSubRow: indexRow.subTableLine
      }
      this.getEdit.emit(editedItens);
    } else {
      this.editRow[indexRow] = true;
      this.getEdit.emit(this.editRow);
    }
    if (this._dynamicTable.multipleTotals) {
      this.changeMultipleTotal(this.data, indexRow);
      this.edit.emit(this.data);
    }
  }

  setRowCol(row, col) {
    let input = document.getElementById(`[${row + 1}][${col}]`);
    if (!input) {
      input = document.getElementById(`[${0}][${col}]`);
    }
    input.focus();
  }

  decimalFilter(event: any) {
    const reg = /^\d*(?:[.,]\d{0,2})?$/;
    const input = event.target.value + String.fromCharCode(event.charCode);
    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  changeDate(event: any) {
    this.flagFilter = event ? false : true;
  }

  toggleDetails(row: any, index) {
    this.indexSubTable = index
    this.expandedItem = true
    this.attributesSubtable = [];
    const i = this.expandedElements.indexOf(row);
    if (i > -1) {
      this.expandedElements.splice(i, 1); // Remove a linha do array
    } else {
      this.expandedElements.push(row); // Adiciona a linha ao array
    }
    this.subTableOpen = this.expandedElements.length > 0; // Verifica se há alguma linha expandida
  }

  closedSubTable(event) {
    this.expandedElement = null
    this.subTableOpen = false
  }

  classExpanded(row) {
    return this.expandedElements.includes(row)
  }

  getCheckboxAll(checkbox) {
    if (checkbox._checked) {
      this.data.forEach(res => {
        res.checked = checkbox._checked;
        if (checkbox._checked) {
          this.checked.push(res.id);
        }
      })
      this.ids2.splice(0);
      this.ords = [];
      this.ords.push(this.ids);
      this.ids2.push(this.ids);
      if (this.haveSubIds !== undefined) {
        const deleteIds = {
          tableIds: this.ords.toString(),
          subTable: this.haveSubIds
        }
        this.idsOrders.emit(deleteIds);
      } else {
        this.idsOrders.emit(this.ords.toString());
        const objId = {
          ids: this.ids,
          checked: checkbox._checked
        };
        this.nav.changeReturnIds.emit(objId);
      }
    } else {
      this.data.forEach(res => {
        res.checked = checkbox._checked;
      })
      this.ords.splice(0);
      this.ids2.splice(0);
      this.idsOrders.emit(this.ords.toString());
      const objId = {
        ids: this.ids,
        checked: checkbox._checked
      };
      this.nav.changeReturnIds.emit(objId);
    }

  }

  reciveIdsSubTable(event) {
    if (this.ords.length !== 0) {
      const deleteIds = {
        tableIds: this.ords.toString(),
        subTable: event
      }
      this.idsOrders.emit(deleteIds);
      this.haveSubIds = event;
    } else {
      this.idsOrders.emit(event);
      this.haveSubIds = event;
    }
  }

  getCheckboxOne(index) {
    this.checks[index] = !this.checks[index];
    if (this.checks[index]) {
      this.checked.push(this.data[index].id);
      this.ords.push(this.data[index].id);
      if (this.haveSubIds !== undefined) {
        const deleteIds = {
          tableIds: this.ords.toString(),
          subTable: this.haveSubIds
        }
        this.idsOrders.emit(deleteIds);
      } else {
        this.idsOrders.emit(this.ords.toString());
      }
    } else {
      this.checked.splice(this.checked.indexOf(this.data[index].id), 1);
      this.ords.splice(this.ords.indexOf(this.data[index].id), 1);
      if (this.haveSubIds !== undefined) {
        const deleteIds = {
          tableIds: this.ords.toString(),
          subTable: this.haveSubIds
        }
        this.idsOrders.emit(deleteIds);
      } else {
        this.idsOrders.emit(this.ords.toString());
      }
    }
  }

  uncheckAll() {
    this.checked = [];
    this.ords = [];
    this.checks = [];
  }

  getTotalValues(data) {
    this._dynamicTable.displayedColumns.forEach((obj, i) => {
      if (obj.hasSubTotal) {
        const column = {
          total: data.reduce((a, b) => a + b[obj.attribute], 0),
          index: i.toString()
        }
        this.subTotal.push(column);
      } else {
        const column = {
          total: '',
          index: i.toString()
        }
        this.subTotal.push(column);
      }
    });

    if (this._dynamicTable.haveActions) {
      const index = this.subTotal.length.toString();
      const column = {
        total: '',
        index: index
      }
      this.subTotal.push(column);
    }

    this.subTotalAttr = this.subTotal.map(value => value.index)
  }

  onScroll(event: Event) {
    const scrollPosition = (event.target as HTMLElement).scrollLeft
    this.scrollService.emitScroll(scrollPosition)
  }

  rowsMerge() {
    this.dataSource.data$.subscribe((data: any) => {
      this.dataRows = data
    })
    this.rowSpans = this.rowSpanComputer.compute(this.dataRows, this._dynamicTable.displayedColumns);
  }

  emitFilter(event) {
    if (this.salveExpand) {
      if (event['filterParams'].length > 0) {
        event['filterParams'].forEach(item => {
          const index = this.attributes.findIndex(att => att.param === item.param)
          if (index === -1) {
            this.attributes.push(item)
          } else {
            this.attributes[index].value = item.value
          }
        });
      } else {
        const index = this.attributes.findIndex((item) => item.param === event['att']);
        if (index > -1) {
          this.attributes.splice(index, 1);
        }
      }

    }
    this.subTableFilter.emit(event['filterParams'])
  }

  clearSubTable(reset = false): void {
    let itens = ["scenarioId", "monthRef"]
    const attAuxClear = [];
    itens.forEach(item => {
      const index = this.attributes.findIndex(att => att.param === item)
      if (index > -1) {
        attAuxClear.push(this.attributes[index])
      }
    })
    if (attAuxClear.length > 0) {
      this.attributes = attAuxClear
    }
    if (reset) {
      this.subTableOpen = false
      itens = ['month', 'year']
      itens.forEach(e => {
        const index = this.filterParams.findIndex(att => att.param === e)
        if (index > -1) {
          this.filterParams.splice(index, 1)
        }
      })
      this.loadTable();
    }
    this.closedSubTable(null)
  }


  expandAllRows() {
    this.expandAll = !this.expandAll;
    if(this.expandAll){
      const allRows = [];
      this.dataSource.data$.subscribe((res) => {
        res.forEach(element => {
          if(element.subTable){
            allRows.push(element)
          }
        });
      });
      this.expandedElements = [];
      this.expandedElements = allRows;
      // Atualize a propriedade que controla a exibição da sub-tabela
      this.subTableOpen = this.expandedElements.length > 0;
      // Verifique se há alguma linha expandida
    } else{
      this.expandedElements = [];
      this.subTableOpen = false;
    }
  }


  add(event: any, subTable?): void {

    // Cria uma cópia do evento
    const duplicatedEvent = { ...event };

    // Torna as chaves vazias
    this.makeKeysEmpty(duplicatedEvent);

    // Adiciona a chave 'edit' com o valor true
    duplicatedEvent['edit'] = true;

    // Encontra o índice do evento original na array
    // let index = this.dataSource?.external.rows.findIndex(item => item === event);
    const index = this.data.findIndex(item => item === event);

    // Adiciona a cópia do evento na posição seguinte
    // this.dataSource?.external.rows.splice(index + 1, 0, duplicatedEvent);
    this.data.splice(index + 1, 0, duplicatedEvent);

    // Atualiza o contador
    // this.dataSource['external']['count'] = this.dataSource?.external.rows.length;

    // Atualiza a fonte de dados
    this.dataSource.loadRequestOrder(this.data)

    //Emitir novos valores
    this.issueNewData()

    }

    makeKeysEmpty(object){
      const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
      for (const key in object) {
        if (object.hasOwnProperty(key)) {
          if (typeof object[key] === 'object' && object[key] !== null) {
            this.makeKeysEmpty(object[key]);
          } else {
            if(typeof object[key] === 'string' && regex.test(object[key])) {
              object[key] = new Date().toISOString()
            }else{
              object[key] = typeof object[key] === 'number' ? 0 : '-'
            }
          }
        }
      }

    }

    issueNewData(){
      const newValue = []
      this.data.forEach((item,index) => {
        if(item.edit){
          newValue.push({...item});

          newValue.forEach((item) => {
            delete item.edit
          })
        }
      })

      this.getAdd.emit(newValue)
    }


  setAdd(indexRow){
    this.issueNewData()
  }

  deleteAdd(row, dataIndex?){
    let data
    if(row?.edit){
      data = this.data.filter((item,index) => index !== dataIndex)
      this.dataSource.loadRequestOrder(data)
      this.data = data
    }

    this.issueNewData()
  }

}
