import { Component, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataModal } from '../../../../utils/models/modal.interface';

@Component({
  selector: 'modal-spic',
  templateUrl: './modal-spic.component.html',
  styleUrls: ['./modal-spic.component.scss']
})
export class ModalSpicComponent {
  @Output() buttonEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    public dialogRef: MatDialogRef<ModalSpicComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataModal,
  ) { }

  genericButton(id: string, close: boolean): void {
    if (close) {
      this.dialogRef.close(id);
    } else {
      this.buttonEvent.emit(id);
    }
  }
}
