import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpService } from 'src/app/service/http/http.service';
import { IndexedDbService } from 'src/app/shared/database/indexed-db.service';
import { isOnline } from 'src/utils/functions/utilities.service';
import { RequestAttribute } from 'src/utils/models/http.interface';
import { UpdatesOfflineInterface } from 'src/utils/models/offline.interface';

@Injectable({
  providedIn: 'root'
})
export class SyncDataService {
  subscribeDestroyed: Subject<any> = new Subject();
  statusOnline = isOnline;

  constructor(
    private readonly httpService: HttpService,
    private readonly indexedDbService: IndexedDbService
  ) { }

  formatResponseData(res: any, primaryKey: any) {
    let value = [];
    if (res !== undefined) {
      if (res.rows) {
        value = res.rows;
      } else if (res.withGroup) {
        value = res.withGroup;
      } else {
        value = res
      }
    }
    return value.map(val => {
      val.searchValue = val[primaryKey];
      return val;
    });
  }

  deleteUpdateOfSchema(index: string): void {
    this.indexedDbService.deleteByKey('updates', index).subscribe();
  }

  synchronizeUpdate(data: UpdatesOfflineInterface): void {
    if (data.method === 'PUT') {
      this.httpService.genericPut(data.endpoint, data.body).subscribe(res => {
        this.deleteUpdateOfSchema(data._id);
      });
    } else if (data.method === 'POST') {
      this.httpService.genericPost(data.endpoint, data.body, {}).subscribe(res => {
        this.deleteUpdateOfSchema(data._id);
      });
    }
  }

  updateDataOffline() {
    this.indexedDbService.getAllSchema('updates').subscribe((res) => {
      if (res.length > 0) {
        res.forEach(item => {
          this.synchronizeUpdate(item);
        });
      }
    }, error => {});
  }

  syncDataIndexed(syncAfterOffline: boolean): Promise<boolean> {
    return new Promise((resolve) => {
      if (this.statusOnline()) {
        this.updateDataOffline();
        this.indexedDbService.returnSchemasAndEndpoints().forEach(data => {
          this.indexedDbService.getAllSchema(data.schema).pipe(takeUntil(this.subscribeDestroyed)).subscribe(res => {
            const idsSync = res.map(item => item._id);
            const params: RequestAttribute[] = [{param: 'ids', value: idsSync.join(',')}];
            if (idsSync.length > 0) {
              this.httpService.genericGetParams(data.endpoint, params).pipe(takeUntil(this.subscribeDestroyed)).subscribe((res: any) => {
                if (res) {
                  this.indexedDbService.deleteAllBySchema(data.schema).pipe(takeUntil(this.subscribeDestroyed)).subscribe();
                  this.indexedDbService.addList(data.schema, this.formatResponseData(res, data.primaryKey)).pipe(takeUntil(this.subscribeDestroyed)).subscribe();
                }
                resolve(true);
              }, error => {
                resolve(syncAfterOffline);
              })
            } else {
              resolve(true);
            }
          }, error => {
            resolve(false);
          });
        });
      } else {
        resolve(false);
      }
    })
  }
}
