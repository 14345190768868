import { Component } from '@angular/core';
import { FilterSpicService } from '../../services/filter-spic.service';

@Component({
  selector: 'button-filter-spic',
  templateUrl: './button-filter-spic.component.html',
  styleUrls: ['./button-filter-spic.component.scss']
})
export class ButtonFilterSpicComponent {
  constructor(
    private readonly filterSpicService: FilterSpicService
  ) { }

  handleChangeFilter(): void {
    this.filterSpicService.handleChangeOpenFilter();
  }
}
