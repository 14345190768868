import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { DataFilterSpic, RemoveAplliedFiltersSpic, SubGroupFilterSpic } from 'src/utils/models/filter-spic.interface';
import { FilterSpicService } from './services/filter-spic.service';
import { RequestAttribute } from 'src/utils/models/http.interface';
import { ToastrService } from 'ngx-toastr';
import { changeRequestParameter } from 'src/utils/functions/utilities.service';

@Component({
  selector: 'filter-spic',
  templateUrl: './filter-spic.component.html',
  styleUrls: ['./filter-spic.component.scss']
})
export class FilterSpicComponent implements OnInit, OnChanges, OnDestroy {
  @Input() subGroup: SubGroupFilterSpic;
  @Input() filters: DataFilterSpic[];
  @Input() attributes: RequestAttribute[] = [];
  @Input() removedAppliedFilter: RemoveAplliedFiltersSpic;
  @Input() reset: number;

  @Output() changeFilter: EventEmitter<RequestAttribute[]> = new EventEmitter();
  activeFilter: boolean;
  changeSelect = 0;

  constructor(
    private readonly toastr: ToastrService,
    private readonly filterSpicService: FilterSpicService,
    private readonly cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    if (this.attributes?.length > 0) {
      this.attributes.forEach(item => {
        this.addValueSelectFilters(item.param, item.value)
      });
      this.changeSelect++;
    }
    this.activeFilter = this.filterSpicService.getStatusFilter();
    this.filterSpicService.openFilter.subscribe((open: boolean) => {
      this.activeFilter = open;
    })
  }

  getValueFilter(nowSubGroupValue: string, subGroup: boolean) {
    if (subGroup) {
      return this.filters.filter(res => res.subGroup === nowSubGroupValue);
    }
    return this.filters;
  }

  ngOnChanges(changes): void {
    if (changes?.removedAppliedFilter && !changes.removedAppliedFilter.firstChange) {
      setTimeout(() => {
        if (this.subGroup?.field.key === changes.removedAppliedFilter.currentValue.key) {
          this.handleClearFilter(false);
        } else {
          this.removeValueAplliedFilters(changes.removedAppliedFilter.currentValue.key);
        }
        this.cdRef.detectChanges();
      }, 100);
    }
    if (changes?.reset && !changes.reset.firstChange) {
      this.resetData();
    }
  }

  onValueEmitterDatapickerInput(value, key: string, paramName: string) {
    const event = {
      key: key,
      param: paramName,
      value: value
    }
    this.handleChangeElement(event);
  }

  removeValueAplliedFilters(key: string): void {
    this.addValueSelectFilters(key, null);
    this.changeSelect++;
  }

  verifyExistAndKey(field, property: string, key: string) {
    return (field && field[property] && field[property].key === key);
  }

  addValueSelectFilters(key: string, value: any): void {
    if (this.verifyExistAndKey(this?.subGroup, 'field', key)) {
      this.subGroup.field.value = value;
    }
    this.filters.forEach(filter => {
      filter.fields.forEach(field => {
        if (field.select) {
          if (field.select.key === key) {
            field.select.value = value;
          }
          field.select.attributes = this.attributes;
        }
        if (this.verifyExistAndKey(field, 'input', key)) {
          field.input.value = value;
        }
        if (this.verifyExistAndKey(field, 'date', key)) {
          field.date.dateInterface.date = value;
        }
      })
    });
  }

  handleChangeElement(event): void {
    if (event) {
      if (this.subGroup?.field.key === event.key && this.subGroup.field.value !== event.value) {
        this.subGroup.field.value = event.value;
        this.resetData();
      }
      if (event.value !== null && event.value !== undefined && event.value !== '') {
        this.attributes = changeRequestParameter(event.param, event.value, false, this.attributes);
      } else {
        this.attributes = changeRequestParameter(event.param, event.value, true, this.attributes);
      }
    }
    this.addValueSelectFilters(event.key, event.value);
    this.changeFilter.emit(this.attributes);
  }

  handleCloseFilter() {
    this.filterSpicService.handleChangeOpenFilter();
  }

  resetData() {
    this.attributes = [];
    this.filters.forEach(filter => {
      filter.fields.forEach(field => {
        if (field.select) {
          field.select.value = '';
          field.select.attributes = [];
        }
        if (field.input) {
          field.input.value = '';
        }
        if (field.date) {
          field.date.dateInterface.date = null;
        }
      })
    });
    this.changeSelect++;
  }

  handleClearFilter(showMessage: boolean) {
    this.resetData();
    if (this.subGroup) {
      this.subGroup.field.value = '';
    }
    this.changeFilter.emit(this.attributes);
    if (showMessage) {
      this.toastr.show('Limpeza dos filtros efetuada com sucesso!', 'Sucesso!', {
        timeOut: 5000,
        messageClass: 'success',
        titleClass: JSON.stringify({})
      });
    }
  }

  handleClearFilterMobile() {
    this.handleClearFilter(true);
    this.handleCloseFilter();
  }

  ngOnDestroy(): void {
    this.resetData();
    if (this.subGroup) {
      this.subGroup.field.value = '';
    }
    this.filterSpicService.handleCloseImmediatelyFilter();
  }
}
