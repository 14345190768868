import { Component, EventEmitter, Input, Output } from '@angular/core';
import { showTheData, verifyShowMobile } from 'src/utils/functions/utilities.service';
import { ActionButtonAccodionSpic, ButtonsAccodionSpic, CreateDataAccordionSpic } from 'src/utils/models/accordion-spic.interface';
import { TagSpicInterface } from 'src/utils/models/tags.interface';

@Component({
  selector: 'accordion-spic',
  templateUrl: './accordion-spic.component.html',
  styleUrls: ['./accordion-spic.component.scss']
})
export class AccordionSpicComponent {
  @Input() value: any;
  @Input() tag: TagSpicInterface;
  @Input() buttons: ButtonsAccodionSpic[];
  @Input() data: CreateDataAccordionSpic;

  @Output() actionButton: EventEmitter<ActionButtonAccodionSpic> = new EventEmitter();

  verifyShow = verifyShowMobile;
  showData = showTheData;

  handleClickButton(key: string): void {
    this.actionButton.emit({
      key,
      value: this.value
    });
  }
}
