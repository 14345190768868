// Depedencies
import { NgModule, LOCALE_ID, APP_INITIALIZER} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

// Language
import { registerLocaleData } from '@angular/common';
import localept from '@angular/common/locales/pt';
registerLocaleData(localept, 'pt');

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { AuthService } from './service/auth/auth.service';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AuthInterceptor } from './service/auth/auth.interceptor';
import { ToastrService } from 'ngx-toastr';
import { checkForUpdates } from 'src/utils/functions/utilities.service';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.PRODUCTION,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  exports: [
    BrowserAnimationsModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: HTTP_INTERCEPTORS,
      useFactory: function(authService: AuthService, router: Router, dialog: MatDialog, toastr: ToastrService) {
        return new AuthInterceptor(authService, router, dialog, toastr);
      },
      multi: true,
      deps: [AuthService, Router, MatDialog, ToastrService]
    },
    { provide: APP_INITIALIZER, useFactory: checkForUpdates, deps: [SwUpdate], multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
