import {EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  isOnline: EventEmitter<boolean> = new EventEmitter();
  protected inOnline = true;

  get status(): boolean {
    return this.inOnline;
  }

  constructor() {
    this.checkStatusConnection();
  }

  private checkStatusConnection() {
    this.changeStatus();
    window.addEventListener('online',  this.changeStatus.bind(this));
    window.addEventListener('offline', this.changeStatus.bind(this));
  }

  private changeStatus(): void {
    this.inOnline = window.navigator.onLine;
    this.isOnline.emit(this.inOnline);
  }
}
