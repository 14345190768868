import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalTutorialSpicComponent } from './components/modal-tutorial-spic/modal-tutorial-spic.component';
import mockTutorial from '../../../../utils/constants/mocks/common/components/tutorial.mock.json';
import { getItemLocalStorage, setItemLocalStorage } from 'src/utils/functions/utilities.service';

@Component({
  selector: 'tutorial-spic',
  templateUrl: './tutorial-spic.component.html',
  styleUrls: ['./tutorial-spic.component.scss']
})
export class TutorialSpicComponent implements OnInit {
  dataTutorial: any = mockTutorial;
  firstAccess = getItemLocalStorage('tutorial');

  constructor(
    private readonly dialog: MatDialog
  ) { }

  ngOnInit(): void {
    if (!this.firstAccess) {
      this.handleOpenModalTutorial();
      setItemLocalStorage('tutorial', 'true');
    }
  }

  handleOpenModalTutorial(): void {
    this.dialog.open(ModalTutorialSpicComponent, {
      autoFocus: false,
      width: '47.5rem',
      data: this.dataTutorial,
      panelClass: 'modal-tutorial-panel'
    });
  }

}
