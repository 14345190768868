import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { changeRequestParameter, convertData } from 'src/utils/functions/utilities.service';
import { ChipsSpicActivatedInterface } from 'src/utils/models/chips.interface';
import { AplliedFiltersSpic, MultipleAttr, ReturnAplliedFiltersSpic } from 'src/utils/models/filter-spic.interface';
import { RequestAttribute } from 'src/utils/models/http.interface';

@Component({
  selector: 'applied-filters',
  templateUrl: './applied-filters.component.html',
  styleUrls: ['./applied-filters.component.scss']
})
export class AppliedFiltersComponent {
  @Input() attributes: RequestAttribute[];
  @Input() aplliedFilters: AplliedFiltersSpic[];

  @Output() changeFilter: EventEmitter<ReturnAplliedFiltersSpic> = new EventEmitter();

  constructor(
    private readonly element: ElementRef
  ) { }

  formatTitle(param: string): string {
    const findTitle = this.aplliedFilters?.find(res => res.attribute === param);
    if (findTitle !== undefined) {
      const findParam = this.attributes?.find(res => res.param === 'type');
      if (
        findParam !== undefined && findParam.value === 'si' &&
        (findTitle.attribute === 'serviceDescription' || findTitle.attribute === 'description')
      ) {
        return findTitle.attribute === 'serviceDescription' ? 'Descrição do ativo' : 'Serviço a executar';
      } else {
        return findTitle.name;
      }
    } else {
      return '-';
    }
  }

  verifyAttrIsMultiple(param: string): boolean {
    const findAttr = this.aplliedFilters.find(res => res.attribute === param);
    return (findAttr && findAttr?.multiple === true ? true : false);
  }

  formatDataValue(key: string, value: any): string {
    const findChip = this.aplliedFilters?.find(res => res.attribute === key);
    return (findChip !== undefined ? convertData((findChip.type || ''), value) : '-');
  }

  handleClickChipFilter(event: ChipsSpicActivatedInterface, multiple?: MultipleAttr): void {
    if (multiple) {
      const indexVal = multiple.allValue.indexOf(multiple.nowValue);
      if (indexVal !== -1) {
        multiple.allValue.splice(indexVal, 1);
        const lastValue = (multiple.allValue?.length === 0 ? true : false);
        this.attributes = changeRequestParameter(multiple.param, multiple.allValue, lastValue, this.attributes);
      }
    } else {
      this.attributes = changeRequestParameter(event.key, '', true, this.attributes);
    }
    this.element.nativeElement.querySelector(`#filter-${event.key}`)?.remove();
    this.changeFilter.emit({
      key: event.key,
      param: event.key,
      attributes: this.attributes
    });
  }

  get arrayHiddens(): string[] {
    return this.aplliedFilters?.filter(res => res?.type === 'hidden').map(item => item.attribute) || [];
  }

  get attributesNotHidden(): RequestAttribute[] {
    return this.attributes?.filter(res => !(this.arrayHiddens.includes(res.param))) || [];
  }
}
