import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FilterSpicService {
  openFilter: EventEmitter<boolean> = new EventEmitter();
  private beforeValue: boolean;

  handleChangeOpenFilter() {
    this.beforeValue = !this.beforeValue;
    this.openFilter.emit(this.beforeValue);
  }

  handleCloseImmediatelyFilter() {
    this.beforeValue = false;
    this.openFilter.emit(this.beforeValue);
  }

  getStatusFilter(): boolean {
    return this.beforeValue;
  }

  verifyShowButton(path: string): boolean {
    if (path === '/qrcode-manager' || path === '/dashboard') {
      return true;
    }
    return false
  }
}
