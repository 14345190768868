import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ChipsSpicActivatedInterface, ChipsSpicInterface } from 'src/utils/models/chips.interface';

@Component({
  selector: 'chips-spic',
  templateUrl: './chips-spic.component.html',
  styleUrls: ['./chips-spic.component.scss']
})
export class ChipsSpicComponent {
  @Input() chipsInterface: ChipsSpicInterface;

  @Output() activated: EventEmitter<ChipsSpicActivatedInterface> = new EventEmitter();
  @Output() onClose: EventEmitter<string> = new EventEmitter();

  constructor(
    private readonly element: ElementRef
  ) { }

  changeActivated(): void {
    if (!this.chipsInterface?.disabled && this.chipsInterface?.clickable) {
      const active = !this.chipsInterface.active
      this.chipsInterface.active = active;
      this.activated.emit({
        key: this.chipsInterface.key,
        active
      });
    }
  }

  changeClose(): void {
    if (!this.chipsInterface?.disabled && this.chipsInterface?.close) {
      this.element.nativeElement.remove();
      this.onClose.emit(this.chipsInterface.key);
    }
  }
}
