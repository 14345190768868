import { Component, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalInstallIosComponent } from '../modal-install-ios/modal-install-ios.component';

@Component({
  selector: 'install-pwa',
  templateUrl: './install-pwa.component.html',
  styleUrls: ['./install-pwa.component.scss']
})
export class InstallPwaComponent {
  constructor(
    private readonly dialog: MatDialog,
  ) {
  }
  deferredPrompt: any;
  showButton = false;

  @HostListener('window:beforeinstallprompt', ['$event'])

  onbeforeinstallprompt(e: any) {
    e.preventDefault();
    this.deferredPrompt = e;
    this.showButton = true;
  }

  installPwa() {
    if (this.deferredPrompt) {
      this.showButton = false;
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome !== 'accepted') {
          this.showButton = true;
        }
        this.deferredPrompt = null;
      });
    } else {
      this.dialog.open(ModalInstallIosComponent, {
        autoFocus: false,
        width: '23.4375rem',
        data: {},
      });
    }
  }

  get verifyInstallPwa(): boolean {
    const nav: any = window.navigator;
    if (navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPod/i)) {
      return !(('standalone' in nav) && (nav.standalone));
    }
    return this.showButton;
  }
}
