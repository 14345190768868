import { Component, Input, OnInit } from '@angular/core';
import { DataValueGaugeChart } from 'src/utils/models/graphics.interface';

@Component({
  selector: 'graphic-gauge-chart',
  templateUrl: './graphic-gauge-chart.component.html',
  styleUrls: ['./graphic-gauge-chart.component.scss']
})
export class GraphicGaugeChartComponent implements OnInit {
  @Input() layoutGraphic: any;
  @Input() dataValue: DataValueGaugeChart;
  @Input() configGraphic: any;

  emptyGraphic: boolean;

  delta: number;

  labelsLegend = [];
  dataGraphic = [];
  legendHidden = [
    false, false
  ];

  ngOnInit(): void {
    if (this.dataValue && this.dataValue?.primary > 0) {
      this.delta = (this.dataValue.primary - (this.dataValue.secondary - this.dataValue.primary));
      this.labelsLegend.push(this.mountLegendGauge(this.dataValue.labels.primary, this.dataValue.colors.primary));
      this.labelsLegend.push(this.mountLegendGauge(this.dataValue.labels.secondary, this.dataValue.colors.secondary));
      this.changeDataGraphic();
    } else {
      this.emptyGraphic = true;
    }
  }

  mountLegendGauge(name: string, color: string) {
    return {
      dataSetName: name,
      label: name,
      backgroundColor: color,
      borderColor: color,
      order: 1,
      icon: 'square'
    }
  }

  formatTickVals(): number[] {
    const calculate = Math.round(this.dataValue.maxValue / 10), arrayValue = [0];
    let value = 0;
    for (let index = 0; index < 9; index++) {
      value += calculate;
      arrayValue.push(value)
    }
    arrayValue.push(this.dataValue.maxValue)
    return arrayValue;
  }

  changeDataGraphic() {
    let valueFilter = this.dataValue.primary, modeFilter = "gauge+number", steps = [];
    let barColor = this.dataValue.colors.primary;
    if (this.legendHidden[0] === true && this.legendHidden[1] === false) {
      valueFilter = (this.dataValue.secondary - this.dataValue.primary);
      barColor = this.dataValue.colors.secondary;
    } else if (this.legendHidden[0] === true && this.legendHidden[1] === true) {
      valueFilter = 0;
    } else if (this.legendHidden[0] === false && this.legendHidden[1] === false){
      modeFilter = this.dataValue.mode;
      steps = [
        { range: [0, this.dataValue.secondary], color: this.dataValue.colors.secondary },
      ]
    }
    this.dataGraphic = [
      {
        type: "indicator",
        mode: modeFilter,
        value: valueFilter,
        delta: {
          reference: this.delta,
          font: {
            family: 'Inter Regular',
            size: 32
          },
          increasing: { color: this.dataValue.colors.secondary, symbol: '' },
          decreasing: { color: this.dataValue.colors.secondary, symbol: '' }
        },
        number: {
          font: {
            family: 'Inter Regular',
            size: 48
          },
          suffix: (this.dataValue.percentage ? '%' : '' )
        },
        gauge: {
          axis: {
            range: [0, this.dataValue.maxValue],
            tickvals: this.formatTickVals(),
            tickfont: {
              family: 'Inter Regular',
              size: 12,
              color: '#000000'
            }
          },
          bar: { color: barColor },
          bgcolor: "white",
          borderwidth: 1,
          bordercolor: "#000000",
          steps: steps,
        }
      },
    ];
    if (this.dataValue.percentage) {
      this.dataGraphic[0].gauge.axis.ticktext = ['0%', '10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'];
    }
  }

  hiddenDataGraphic(event): void {
    const index: any = event.dataSetIndex;
    this.legendHidden[index] = !this.legendHidden[index];
    this.changeDataGraphic();
  }
}
