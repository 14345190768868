import { Component, DoCheck, OnInit } from '@angular/core';
import moment from 'moment';
import { SyncDataService } from './services/sync-data.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { isOnlineEmitter } from 'src/utils/functions/utilities.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'sync-data',
  templateUrl: './sync-data.component.html',
  styleUrls: ['./sync-data.component.scss']
})
export class SyncDataComponent implements OnInit, DoCheck {
  emitterOnline = isOnlineEmitter();

  constructor(
    private readonly syncDataService: SyncDataService,
    private readonly toastr: ToastrService,
    public readonly dialog: MatDialog,
  ) { }

  private isOnlineSubject: Subject<boolean> = new Subject<boolean>();

  ngOnInit() {
    this.emitterOnline.pipe(
      takeUntil(this.isOnlineSubject)
    ).subscribe(state => {
      if (state) {
        this.syncData(true);
        this.isOnlineSubject.next();
      }
    });
  }

  ngDoCheck(): void {
    const updateDateIndexed = localStorage.getItem('updateDateIndexed');
    if (updateDateIndexed) {
      if (moment().diff(moment.parseZone(new Date(updateDateIndexed)), 'minutes') > 10) {
        this.syncData();
      }
    } else {
      this.syncData();
    }
  }

  async syncData(syncAfterOffline = false) {
    localStorage.setItem('updateDateIndexed', `${moment.parseZone()}`);
    const syncDataIndexed = await this.syncDataService.syncDataIndexed(syncAfterOffline);
    if (syncDataIndexed) {
      this.toastr.show('Sincronização realizada com sucesso.', 'Sincronizar dados!', {
        timeOut: 5000,
        messageClass: 'success',
        titleClass: JSON.stringify({})
      });
    } else {
      this.toastr.show('Ocorreu um erro ao tentar fazer a sincronização.', 'Erro ao sincronizar!', {
        timeOut: 5000,
        messageClass: 'error',
        titleClass: JSON.stringify({})
      });
    }
  }
}
