import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { HttpService } from 'src/app/service/http/http.service';
import { IndexedDbService } from 'src/app/shared/database/indexed-db.service';
import { changeRequestParameter, isOnline, searchInObject, sortAsc } from 'src/utils/functions/utilities.service';
import { RequestAttribute } from 'src/utils/models/http.interface';
import { MockDataInfiniteScrollSpicInterface } from 'src/utils/models/select.interface';

@Injectable({
  providedIn: 'root'
})
export class SelectInfiniteScrollSpicService {
  statusOnline = isOnline;
  constructor(
    private readonly httpService: HttpService,
    private readonly indexedDbService: IndexedDbService
  ) { }

  listDataSelectOffline(data: any, value: string, searchAttibute: string, extraParams: RequestAttribute[], mockData?: MockDataInfiniteScrollSpicInterface) {
    if (!mockData) {
      const params = changeRequestParameter(searchAttibute, (value || ''), (value === null || value === undefined || value === ''), [...extraParams]);
      const returnValues = data.filter(res => searchInObject(res, (value || ''), params, 'one'));
      return returnValues.filter((item, index, arr) =>
        arr.findIndex(res => res[searchAttibute] === item[searchAttibute]) === index
      ).sort((a, b) => sortAsc(a, b, searchAttibute));
    } else {
      return mockData.rows;
    }
  }

  listDataSelect(endpoint: string, value: string, searchAttibute: string, extraParams: RequestAttribute[], mockData?: MockDataInfiniteScrollSpicInterface) {
    const params = changeRequestParameter(searchAttibute, (value || ''), (value === null || value === undefined || value === ''), [...extraParams]);
    if (!mockData) {
      if (this.statusOnline()) {
        return this.httpService.genericGetParams(`${endpoint}`, [...params]);
      } else {
        return this.indexedDbService.getAllSchema('documents');
      }
    } else {
      return of(mockData);
    }
  }
}
