
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RequestAttribute, RequestParams } from 'src/utils/models/http.interface';
import { ConfigApiDynamicTable } from 'src/utils/models/table.interface';
import { SelectInterface } from '@induxtry/library/utils/models/table.interface';

// Interface

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(
    private readonly http: HttpClient,
  ) {}

  genericGetListTable(
    endpoint: string,
    configApi: ConfigApiDynamicTable,
    requestParamsDynamic: RequestParams,
    attrDynamic?: RequestAttribute[],
    filterAttributeDynamic?: RequestAttribute[]
  ): Observable<any> {
    if (configApi) {
      const url = `${configApi.url}/${endpoint}`;
      let paramsDynamic = new HttpParams()
        .set('page', requestParamsDynamic.page?.toString())
        .set('sort', requestParamsDynamic.sort)
        .set('limit', requestParamsDynamic.limit?.toString());
      if (!!attrDynamic) {
        attrDynamic.forEach((att) => paramsDynamic = paramsDynamic.append(att.param, att.value));
      }
      if (!!filterAttributeDynamic) {
        filterAttributeDynamic.forEach((att) => paramsDynamic = paramsDynamic.append(att.param, att.value));
      }
      return this.http.get<any>(url, {
        headers: {
          Authorization: `Bearer ${configApi.token}`
        },
        params: paramsDynamic
       })
        .pipe(map((response: any) => {
          return response;
        }));
    } else {
      return of({
        count: 0,
        rows: []
      })
    }
  }

  genericGetSelect(selectInterface?: SelectInterface): Observable<any>  {
    if (selectInterface && selectInterface.configApi) {
      if (selectInterface.mockData !== undefined) {
        return of(selectInterface.mockData);
      } else {
        const url = `${selectInterface.configApi.url}/${selectInterface.endpoint}`;
        let paramsDynamic = new HttpParams()
        if (!!selectInterface.attributes) {
          selectInterface.attributes.forEach((att) => paramsDynamic = paramsDynamic.append(att.param, att.value));
        }
        return this.http.get<any>(url, {
          headers: {
            Authorization: `Bearer ${selectInterface.configApi.token}`
          },
          params: paramsDynamic
         })
        .pipe(map((response: any) => {
          return response;
        }));
      }
    } else {
      return of([]);
    }
  }
}
