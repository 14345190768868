import { Component, Input } from '@angular/core';
import { CardStatus } from 'src/utils/models/dashboard.interface';

@Component({
  selector: 'card-status-dashboard',
  templateUrl: './card-status-dashboard.component.html',
  styleUrls: ['./card-status-dashboard.component.scss']
})
export class CardStatusDashboardComponent {
  @Input() cardStatus: CardStatus;
}
