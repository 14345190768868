import { Injectable } from '@angular/core';
import { DBConfig, NgxIndexedDBService } from 'ngx-indexed-db';
import documents from '../../../utils/schemas/documents/documents.schema.json';
import updates from '../../../utils/schemas/updates/updates.json';
import { Observable } from 'rxjs';
import { EndpointSyncData } from 'src/utils/models/http.interface';

@Injectable({
  providedIn: 'root'
})
export class IndexedDbService {
  constructor(private readonly dbService?: NgxIndexedDBService) {}

  createDatabase(): DBConfig {
    return {
      name: 'spic-database',
      version: 72,
      objectStoresMeta: [
        documents, updates
      ]
    };
  }



  createObjStore() {
    this.dbService.createObjectStore(documents);
  }

  // retorna o endpoint para popular os schemas
  returnSchemasAndEndpoints(): EndpointSyncData[] {
    return [
      { endpoint: 'search/documentsOffline', schema: 'documents', primaryKey: '_id' },
    ];
  }

  // pegar um de uma dado da tabela
  getByIndex(schema: string, column: string, index: any): Observable<any> {
    return this.dbService.getByIndex(schema, column, index);
  }

  // pegar um de uma dado da tabela pela key
  getByKey(schema: string, key: any): Observable<any> {
    return this.dbService.getByKey(schema, key);
  }

  // ver todos dados do schema
  getAllSchema(schema: string): Observable<any> {
    return this.dbService.getAll(schema);
  }

  // Adicionar um novo dado no schema
  add(schema: string, data: any): Observable<any> {
    return this.dbService.add(schema, data);
  }

  // Adicionar uma nova lista de dados no schema
  addList(schema: string, data: any): Observable<any> {
    return this.dbService.bulkAdd(schema, data);
  }

  // atualizar um novo dado no schema
  update(schema: string, data: any): Observable<any> {
    return this.dbService.update(schema, data);
  }

  // deletar um dado através de uma chave
  deleteByKey(schema: string, key: any): Observable<any> {
    return this.dbService.deleteByKey(schema, key);
  }

  // deletar mais dde um dado através das chaves
  bulkDelete(schema: string, keys: any[]): Observable<any> {
    return this.dbService.bulkDelete(schema, keys);
  }

  // deletar todos os dados do schema
  deleteAllBySchema(schema: string): Observable<any> {
    return this.dbService.clear(schema);
  }
}
